import inFo from '../utils/inFo.js'
import {ElMessage} from "element-plus";

/**
 * tool 工具类
 */

/**
 * base64图片转文件
 * @param urlData  图片数据
 * @param fileName 图片名称
 */
export const base64ToFile = (urlData, fileName) => {
  let arr = urlData.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bytes = atob(arr[1]) // 解码base64
  let n = bytes.length
  let ia = new Uint8Array(n)
  while (n--) {
    ia[n] = bytes.charCodeAt(n)
  }
  return new File([ia], fileName, {
    type: mime
  })
}

/**
 * 网络文件url转文件file
 * @param url  网络文件url
 * @param fileName 文件名称
 */
export const getFileFromUrl = (url, fileName) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((res) => res.blob(), (e) => reject(e))
      .then((blob) => {
        resolve(new File([blob], fileName, { type: blob.type }))
      }, (e) => reject(e))
  })
}

/**
 * 文件大小转换
 * @param size 文件大小（单位：字节）
 * @param fixed 保留小数点后几位
 * 云空间规则：
 * 1MB-1023MB，大于等于1024MB显示为GB+
 * 1KB-1023KB，大于等于1024KB显示为MB
 * 1B-1023B，大于等于1024B显示为KB
 * 存储量：取整(四舍五入)
 * 文件大小：保留小数点后1位(四舍五入)
 */
export function fileSizeConversion (size, fixed) {
  if (size === 0) {
    return '0'
  }
  if (size > 0 && size < 1024) {
    return size + 'B'
  }
  if (size >= 1024 && size < 1024 * 1024) {
    return Number((size / 1024).toFixed(fixed)) + 'KB'
  }
  if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
    return Number((size / 1024 / 1024).toFixed(fixed)) + 'MB'
  }
  if (size >= 1024 * 1024 * 1024 && size < 1024 * 1024 * 1024 * 1024) {
    return Number((size / 1024 / 1024 / 1024).toFixed(fixed)) + 'GB'
  }
}

/**
 * 获取图片文件 file 的信息
 * @param file  图片文件
 */
export const getImgFileInfo = (file) => {
  return new Promise((resolve, reject) => {
    const imgView = new Image()
    imgView.src = file.url
    imgView.onload = () => {
      resolve({ width: imgView.width, height: imgView.height })
    }
    imgView.onerror = () => {
      reject('')
    }
  })
}

/**
 * 特殊字符校验
 * @param  inputText 描述参数
 * @return okText(校验通过返回转义后的描述  false校验失败)
 */
export const inputTextCheck = (inputText) => {
  String.prototype.replaceAll = function (s1, s2) {
    return this.replace(new RegExp(s1, 'gm'), s2)
  }
  // 输入校验
  // 参数校验 校验规则：1.表情禁用；2.英文符号 <> 自动替换成 中文符号（）其他不限制；3.中文符号不限制
  // 中文标点符号 。 ？ ！ ， 、 ； ： “ ” ‘ ' （ ） 《 》 〈 〉 【 】 『 』 「 」 ﹃ ﹄ 〔 〕 … — ～ ﹏ ￥
  // 英文标点符号 `~!#$%^&*()_[]{}\|;'':"",./<>?·
  inputText = inputText.replaceAll('<', '（')
  inputText = inputText.replaceAll('>', '）')
  inputText = inputText.replaceAll(/\n/g, '')
  const pattern = new RegExp('[^\u4E00-\u9FA5A-Za-z0-9(\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5)(\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\_|\\[|\\]|\{|\}|\|\|\||\;|\'|\'|\:|\"|\"|\,|\.|\/|\<|\>|\?\·\ )(\\-|\+|\=)]+')
  const okText = pattern.test(inputText) ? false : inputText
  return okText
}

// 显示loading
export function onLoad () {
  try {
    inFo.emit('loadingDownload', true)
  } catch (e) {
    console.log(e)
  }
}

// 关闭loading
export function offLoad () {
  try {
    inFo.emit('loadingDownload', false)
  } catch (e) {
    console.log(e)
  }
}

// 关闭云空间购买弹窗
export function closeCloudBuyPopup () {
  try {
    inFo.emit('closeCloudBuyPopup')
  } catch (e) {
    console.log(e)
  }
}

// 关闭云空间购买弹窗
export function showCloudUploadHere (isShow, uploadCount) {
  try {
    const uploadHereInfo = {
      isShow: isShow,
      uploadCount: uploadCount
    }
    inFo.emit('showCloudUploadHere', uploadHereInfo)
  } catch (e) {
    console.log(e)
  }
}

/**
 * qt登录
 * @param token  文件地址
 * @param pcversion 版本
 */
export function onLogin(token) {
  try{
    if(!token) return;
    inFo.emit('reLogin', token)
  }catch (e) {
    console.log(e)
  }
}

/**
 * 切换左侧栏当前选中页
 * @param index  当前选中页
 */
export function changeCurPage(index) {
  try{
    inFo.emit('changeCurPage', index)
  }catch (e) {
    console.log(e)
  }
}
